import { useState } from 'react'
import { ForgotPasswordForm } from '../auth-form/forgot-password-form'
import { LoginForm } from '../auth-form/login-form'
import { SignupForm } from '../auth-form/signup-form'
import AuthContainer from '../container/auth-container'
import Modal from '../modal/modal'

interface LoginModalProps {
	toggleLoginModal: (value?: number) => void
	initialPage: number
	text?: string
}

export default function LoginModal({
	toggleLoginModal,
	initialPage,
	text,
}: LoginModalProps) {
	const [modalPage, setModalPage] = useState(initialPage)

	const handlePageChange = (newPage: number) => {
		setModalPage(newPage)
	}
	const handleClose = () => {
		toggleLoginModal(undefined)
	}
	return (
		<Modal onClose={handleClose}>
			<AuthContainer padding={false}>
				{modalPage === 0 ? (
					<LoginForm
						toggleLoginModal={handleClose}
						handlePageChange={handlePageChange}
						text={text}
					/>
				) : modalPage === 1 ? (
					<ForgotPasswordForm handlePageChange={handlePageChange} />
				) : (
					<SignupForm handlePageChange={handlePageChange} />
				)}
			</AuthContainer>
		</Modal>
	)
}
